<template>
    <section class="fullscreen d-flex justfy-content-center">
        <div class="container align-self-center">
            <div class="row text-center">
                <div class="col-12">
                    <img src="./assets/img/logo.svg" class="logo">
                    <ul>
                        <li>Stanleystraat 71</li>
                        <li>3772 KC Barneveld</li>
                        <li><a href="mailto:info@hetgeldersbouwbedrijf.nl">info@hetgeldersbouwbedrijf.nl</a></li>
                        <li><a href="tel:+31622795816">06 - 2279 5816</a></li>
                    </ul>
                    <p class="mb-5">Het Gelders Bouwbedrijf in Barneveld is gericht op u en uw wensen: van een kleine klus tot een groot project. Voor de realisatie van uw project heeft u een
                                    verwachting
                                    gecreëerd. Aan ons de taak om die verwachting waar te maken én zo mogelijk te overtreffen.
                                    Zo doen wij zaken. Ook met u! Meer weten?</p>
                    <a class="btn mx-auto" href="mailto:info@hetgeldersbouwbedrijf.nl">Stuur e-mail</a>
                </div>
            </div>
        </div>
    </section>
</template>
